import { createFileRoute, useParams } from '@tanstack/react-router'
import PlatformRenewalPage from '../../../../../../components/pages/backoffice/platform/PlatformRenewalPage'
import { PermissionChecker } from '../../../../../../components/templates/permission-checker'

const BackofficeRenewalPage = () => {
  const { subscriptionId } = useParams({
    strict: false,
  })

  return (
    <PermissionChecker requiredPermissions={['contracts.sign']}>
      <PlatformRenewalPage key={subscriptionId} />
    </PermissionChecker>
  )
}

export const Route = createFileRoute('/bak/contracts/$subscriptionId/renewal/$endorsementId')({
  component: () => <BackofficeRenewalPage />,
})
